import React from "react"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";
import { Link } from "gatsby"
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Cancellations",
  },
];

const Cancellations = () => (
  <Layout
    title="Cancellations | RF Dance"
    description="RF Dance cancellations"
    pathname="cancellations"
    noHero="no-hero"
    className="pages">
    <div className="container">
      <Breadcrumbs crumbs={crumbs} />
      <div className="cancellations_content">
        <div className="columns">
          <div className="column">
            <Link className="red_link" to="https://docs.google.com/forms/d/1Y2bdgA7q9LvcI8SGawJO5tvxRN7VRmGwz9rJnrkLL9k/edit">Cancel my membership</Link>
          </div>
          <div className="column">
            <Link className="red_link" to="https://docs.google.com/forms/d/13ww2bZbPpkStCsuONbiESC5O65138mN-49MebPwX-3A/edit">Place membership on hold</Link>
          </div>
          <div className="column">
            <Link className="red_link" to="https://docs.google.com/forms/d/e/1FAIpQLSfbifH_PAzHw4zmJ0uroENZvwOVSHve43M6F3W_az-kTmZGoA/viewform">Drop a team</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Cancellations